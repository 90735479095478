import React from "react";

const CustomTextArea = ({ answer, setAnswer, label }) => {
  return (
    <div className="max-w-sm w-100">
      <label
        className={
          "w-100 rounded-5 custom-check " + (answer ? "check-primary" : "")
        }>
        <textarea
          name="quest1"
          value={answer || ""}
          onChange={setAnswer}
          placeholder={label}
          rows="4"
          className="w-100 border-0 rounded-5 ms-1 ps-4 pe-4 pt-2"
        />
      </label>
    </div>
  );
};

export default CustomTextArea;
