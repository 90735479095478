import React from "react";

const CustomTextInput = ({ answer, setAnswer, label }) => {
  return (
    <div className="max-w-sm w-100">
      <label
        className={"w-100 custom-check " + (answer ? "check-primary" : "")}>
        <input
          type="text"
          name="quest1"
          value={answer || ""}
          onChange={setAnswer}
          placeholder={label}
          className="w-100 border-0 rounded-pill ms-1"
        />
      </label>
    </div>
  );
};

export default CustomTextInput;
