import { IconCircleCheck, IconCircleX } from "@tabler/icons";
import React from "react";

const CustomYesNoRadio = ({ active, setActive }) => {
  return (
    <div className="d-flex gap-4 justify-center">
      <label
        className={"custom-check " + (active === "Sim" ? "check-primary" : "")}
        role="button">
        <IconCircleCheck
          size={28}
          color={active === "Sim" ? "#eb595e" : "#999"}
          stroke={1.75}
        />
        <span>Sim</span>
        <input
          hidden
          type="radio"
          name="quest1"
          value={"Sim"}
          checked={active === "Sim"}
          onChange={setActive}
        />
      </label>

      <label
        className={"custom-check " + (active === "Não" ? "check-primary" : "")}
        role="button">
        <IconCircleX
          size={28}
          color={active === "Não" ? "#eb595e" : "#999"}
          stroke={1.75}
        />
        <span>Não</span>
        <input
          hidden
          type="radio"
          name="quest1"
          value={"Não"}
          checked={active === "Não"}
          onChange={setActive}
        />
      </label>
    </div>
  );
};

export default CustomYesNoRadio;
