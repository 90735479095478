import { Outlet } from "react-router-dom";
import "./styles/App.css";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <div className="bg-gradient-primary"></div>
      <Header />
      <div className="container">
        <div className="body">
          <Outlet />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default App;
