import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Inquerito from "./Pages/Inquerito";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Sobre from "./Pages/Sobre";
import Home from "./Pages/Home";
import Aderir from "./Pages/Aderir";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "sobre",
        element: <Sobre />,
      },
      {
        path: "inquerito",
        element: <Inquerito />,
      },
      {
        path: "aderir",
        element: <Aderir />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
