import {
  IconAlertOctagon,
  IconArrowRight,
  IconCircle,
  IconCircleCheck,
  IconHome2,
  IconPaperclip,
  IconSearch,
  IconUser,
} from "@tabler/icons";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { Flip, toast, ToastContainer } from "react-toastify";
import heroimg from "../assets/heroimages.png";
import { toastOptions } from "../utils/Utils";
import Api from "../utils/Api";
import Pdf from "../assets/documents/IntrucoesAcessoDadosERedes.pdf";

const Aderir = () => {
  const [questionCliente, setQuestionCliente] = useState(null);
  const [questionTipoAdesao, setQuestionTipoAdesao] = useState(null);
  const [questionNIF, setQuestionNIF] = useState("");
  const [questionCPE, setQuestionCPE] = useState("");
  const [questionNomeCliente, setQuestionNomeCliente] = useState("");
  const [questionTelemovelCliente, setQuestionTelemovelCliente] = useState("");
  const [questionEmailCliente, setQuestionEmailCliente] = useState("");
  const [questionMoradaFaturacaoRua, setQuestionMoradaFaturacaoRua] = useState("");
  const [questionMoradaFaturacaoNumPorta, setQuestionMoradaFaturacaoNumPorta] = useState("");
  const [questionMoradaFaturacaoAndarFraccao, setQuestionMoradaFaturacaoAndarFraccao] = useState("");
  const [questionMoradaFaturacaoCodPostal, setQuestionMoradaFaturacaoCodPostal] = useState("");
  const [questionMoradaFaturacaoLocalidade, setQuestionMoradaFaturacaoLocalidade] = useState("");
  const [questionAutorizoAcesso, setQuestionAutorizoAcesso] = useState(null);
  const [clienteInEGASI, setClienteInEGASI] = useState(null);
  const [dadosClienteEGASI, setDadosClienteEGASI] = useState({
    nome: "",
    cpe: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleSearchCliente = () => {
    const nif = questionNIF;
    const cpe = questionCPE;

    setClienteInEGASI(null);

    if (nif.length < 9) {
      toast.error("O contribuinte deve conter 9 algarismos", {
        toastOptions,
      });
    } else if (cpe.length < 20) {
      toast.error("O Código de Ponto de Entrega deve conter 20 algarismos", {
        toastOptions,
      });
    } else {
      setQuestionNIF(nif);
      setQuestionCPE(cpe);
      getClientInfo(nif, cpe);
    }
  };

  const getClientInfo = (nif, cpe) => {
    const params = {
      nif: nif,
      cpe: cpe,
    };

    setIsLoading(true);
    Api.getDetalhesCliente(params)
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        if (res.data.status === "ok") {
          setDadosClienteEGASI({
            nome: res.data.nome,
            cpe: cpe,
          });
          setClienteInEGASI(true);
          toast.success("Cliente encontrado", {
            toastOptions,
          });
        } else {
          toast.error(res.data.errors, {
            toastOptions,
          });
          setClienteInEGASI(false);
          setDadosClienteEGASI({
            nome: "",
            cpe: "",
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const handleAderir = () => {
    if (questionCliente === true) {
      if (questionNIF.length < 9) {
        toast.error("O contribuinte deve ser válido", {
          toastOptions,
        });
      } else if (questionCPE.length < 20) {
        toast.error("O Código de Ponto de Entrega deve ser válido", {
          toastOptions,
        });
      } else if (!questionAutorizoAcesso) {
        toast.error(
          "Deve autorizar na E-Redes o acesso aos dados de consumo, caso já o tenha feito deve selecionar a opção.",
          {
            toastOptions,
          }
        );
      } else if (clienteInEGASI === null) {
        toast.error(
          "Deve validar o contribuinte e o ponto de entrega, preenchendo as respetivas caixas de texto e clicando na lupa",
          {
            toastOptions,
          }
        );
      } else {
        adesaoCliente(questionNIF, questionCPE);
      }
    } else {
      //adesaaoNaoCliente
      /* questionTipoAdesao
      questionNIF
      questionNomeCliente
      questionTelemovelCliente
      questionEmailCliente
      questionCPE
      questionMoradaFaturacaoRua
      questionMoradaFaturacaoNumPorta
      questionMoradaFaturacaoAndarFraccao
      questionMoradaFaturacaoCodPostal
      questionMoradaFaturacaoLocalidade */

      if (questionNIF.length < 9) {
        toast.error("O contribuinte deve ser válido", {
          toastOptions,
        });
      } else if (questionCPE.length < 20) {
        toast.error("O Código de Ponto de Entrega deve ser válido", {
          toastOptions,
        });
      } else if (questionTipoAdesao === null) {
        toast.error(
          "Deve indicar qual é o tipo de adesão",
          {
            toastOptions,
          }
        );
      } else if (questionNomeCliente.length === 0) {
        toast.error(
          "O Nome cliente é obrigatório.",
          {
            toastOptions,
          }
        );
      } else if (questionTelemovelCliente.length === 0) {
        toast.error(
          "O Telemóvel é obrigatório.",
          {
            toastOptions,
          }
        );
      } else if (questionEmailCliente.length === 0) {
        toast.error(
          "O Email é obrigatório.",
          {
            toastOptions,
          }
        );
      } else if (questionMoradaFaturacaoRua.length === 0) {
        toast.error(
          "A Rua é obrigatória.",
          {
            toastOptions,
          }
        );
      } else if (questionMoradaFaturacaoNumPorta.length === 0) {
        toast.error(
          "O Número de Porta é obrigatório.",
          {
            toastOptions,
          }
        );
      } else if (questionMoradaFaturacaoCodPostal.length === 0) {
        toast.error(
          "O Código Postal é obrigatório.",
          {
            toastOptions,
          }
        );
      } else if (questionMoradaFaturacaoLocalidade.length === 0) {
        toast.error(
          "A Localidade é obrigatória.",
          {
            toastOptions,
          }
        );
      } else if (!questionAutorizoAcesso) {
        toast.error(
          "Deve autorizar na E-Redes o acesso aos dados de consumo, caso já o tenha feito deve selecionar a opção.",
          {
            toastOptions,
          }
        );
      } else {
        adesaoNaoCliente();
      }

    }
  };

  const adesaoCliente = (nif, cpe) => {
    const params = {
      nif: nif,
      cpe: cpe,
      locale: "pt",
    };

    setIsLoading(true);
    Api.adesaoCliente(params)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "ok") {
          toast.success("Registado com sucesso", {
            toastOptions,
          });
        } else {
          res.data.errors.map(o => toast.error(o, {
            toastOptions,
          }))
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  const adesaoNaoCliente = () => {
    const params = {
      tipoAdesao: questionTipoAdesao,
      tipoDF: "PT_NIF",
      nif: questionNIF,
      nome: questionNomeCliente,
      telemovel: questionTelemovelCliente,
      email: questionEmailCliente,
      cpe: questionCPE,
      //tipoPagamento: "MB",
      moradaFaturacao: {
        rua: questionMoradaFaturacaoRua,
        numPorta: questionMoradaFaturacaoNumPorta,
        andarFraccao: questionMoradaFaturacaoAndarFraccao,
        codigoPostal: questionMoradaFaturacaoCodPostal,
        localidade: questionMoradaFaturacaoLocalidade,
      },
      locale: "pt",
    };

    setIsLoading(true);
    Api.adesaoNaoCliente(params)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === "ok") {
          toast.success("Registado com sucesso", {
            toastOptions,
          });
        } else {
          res.data.errors.map(o => toast.error(o, {
            toastOptions,
          }))
        }
      })
      .catch((e) => {
        setIsLoading(false);
        console.log(e);
      });
  };

  /* 
  ## Cliente
  tipoAdesao	{DOMESTICO,EMPRESA}
  tipoDF		{PT_NIF,ES_NIF,ES_NIE,ES_DNI,ES_CIF}
  numeroDF	
  nome
  telemovel
  email
  
  tipoPagamento	string	true		Type of invoice's payment. See getTarifas().
  diasPagamento	integer	true		Payment days. See getTarifas().
  
  ## Morada
  moradaFaturacao			
  moradaFaturacao[rua]		
  moradaFaturacao[numPorta]	string	false		Door's number.
  moradaFaturacao[andarFraccao]	string	false		Number of floor, fraction, block...
  moradaFaturacao[codigoPostal]
  moradaFaturacao[localidade]

  #Servico
  tipoAdesao: 'DOMESTICO',
  tipoPE: 'PT_ELETRICIDADE',
  idComercial: 1,
  alteracaoTitular: false,
  tipoPagamento: 'MB',
  idTarifa: ???,
  tipoEntregaFatura: 'ELETRONICA',
  contaCerta: false,
  idHorario: ???,
  idCiclo: ???,
  dataDenuncia: null,
  dataAssinaturaContrato: ??? date_today,
  validade: ???,
  cae: ???,
  consumoAnual: ???,
  comentarios: null,
  pontoEntrega: ???,
  faturacaoNDF: ???,
  cobrancaNDF: ???,
  potencias: [{
      id: ???,
      valor: ???
  }],
  moradaLocalizacao: ???,
  moradaCorrespondencia: ???
   
  */

  let questions = [
    {
      id: 1,
      quest: "É cliente usenergy?",
      answerElement: (
        <div className="radio-group ms-3">
          <label role="button">
            {questionCliente === true ? (
              <IconCircleCheck size={20} color={"#222"} stroke={1.5} />
            ) : (
              <IconCircle size={20} color={"#222"} stroke={1.5} />
            )}
            <span>Sim</span>
            <input
              hidden
              type="radio"
              name="isCliente"
              value={true}
              onClick={(e) => setQuestionCliente(true)}
            />
          </label>
          <label role="button">
            {questionCliente === false ? (
              <IconCircleCheck size={20} color={"#222"} stroke={1.5} />
            ) : (
              <IconCircle size={20} color={"#222"} stroke={1.5} />
            )}
            <span>Não</span>
            <input
              hidden
              type="radio"
              name="isCliente"
              value={false}
              onClick={(e) => setQuestionCliente(false)}
            />
          </label>
        </div>
      ),
      answer: null,
      condition: null,
    },
    {
      id: 2,
      quest: "Dados de cliente",
      answerElement: (
        <div className="ms-3 ">
          <div className="d-flex gap-4 align-items-center">
            <div className="custom-rounded-input w-100">
              <input
                type="text"
                name="nif"
                id="nif"
                placeholder="Número contribuinte"
                onChange={(e) => setQuestionNIF(e.target.value)}
                maxLength={9}
              />
            </div>
            <div className="custom-rounded-input w-100">
              <input
                type="text"
                name="cpe"
                id="cpe"
                placeholder="Código de Ponto de Entrega"
                onChange={(e) => setQuestionCPE(e.target.value)}
                maxLength={20}
              />
            </div>
            <label role="button" className="input-button">
              <IconSearch
                onClick={() => handleSearchCliente()}
                size={20}
                color={"#222"}
                stroke={1.5}
              />
            </label>
          </div>
          <div>
            {clienteInEGASI !== null &&
              (clienteInEGASI ? (
                <ul className="nif-info">
                  <li className="info-item">
                    <IconUser size={20} color={"#222"} stroke={2} />
                    <span>{dadosClienteEGASI.nome}</span>
                  </li>
                  <li className="info-item">
                    <IconHome2 size={20} color={"#222"} stroke={2} />
                    <span>{dadosClienteEGASI.cpe}</span>
                  </li>
                </ul>
              ) : (
                <ul className="nif-info">
                  <li className="info-item">
                    <IconAlertOctagon size={20} color={"#eb595e"} stroke={2} />
                    <span style={{ color: "#eb595e" }}>
                      O Contribuitnte e CPE que colocou não se encontram
                      registados
                    </span>
                  </li>
                </ul>
              ))}
          </div>
        </div>
      ),
      answer: null,
      condition: questionCliente === true,
    },
    {
      id: 3,
      quest: "Dados de cliente",
      answerElement: (
        <>
          <div className="ms-3">
            <div className="radio-group ">
              <label role="button">
                {questionTipoAdesao === "DOMESTICO" ? (
                  <IconCircleCheck size={20} color={"#222"} stroke={1.5} />
                ) : (
                  <IconCircle size={20} color={"#222"} stroke={1.5} />
                )}
                <span>Doméstico</span>
                <input
                  hidden
                  type="radio"
                  name="tipoCliente"
                  value={"DOMESTICO"}
                  onClick={(e) => setQuestionTipoAdesao("DOMESTICO")}
                />
              </label>
              <label role="button">
                {questionTipoAdesao === "EMPRESA" ? (
                  <IconCircleCheck size={20} color={"#222"} stroke={1.5} />
                ) : (
                  <IconCircle size={20} color={"#222"} stroke={1.5} />
                )}
                <span>Empresa</span>
                <input
                  hidden
                  type="radio"
                  name="tipoCliente"
                  value={"EMPRESA"}
                  onClick={(e) => setQuestionTipoAdesao("EMPRESA")}
                />
              </label>
            </div>
            <div className="custom-rounded-input w-100 mt-2">
              <input
                type="text"
                name="nif2"
                id="nif2"
                placeholder="Número contribuinte"
                onChange={(e) => setQuestionNIF(e.target.value)}
                maxLength={9}
              />
            </div>
            <div className="custom-rounded-input w-100 mt-2">
              <input
                type="text"
                name="nome_cliente"
                id="nome_cliente"
                placeholder="Nome cliente"
                onChange={(e) => setQuestionNomeCliente(e.target.value)}
              />
            </div>
            <div className="custom-rounded-input w-100 mt-2">
              <input
                type="text"
                name="telemovel_cliente"
                id="telemovel_cliente"
                placeholder="Telemóvel"
                onChange={(e) => setQuestionTelemovelCliente(e.target.value)}
              />
            </div>
            <div className="custom-rounded-input w-100 mt-2">
              <input
                type="email"
                name="email_cliente"
                id="email_cliente"
                placeholder="Email"
                onChange={(e) => setQuestionEmailCliente(e.target.value)}
              />
            </div>
          </div>
          <div>
            <p className="aderir-question mt-4">Morada</p>

            <div className="ms-3">
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="cpe2"
                  id="cpe2"
                  placeholder="Código de Ponto de Entrega"
                  onChange={(e) => setQuestionCPE(e.target.value)}
                  maxLength={20}
                />
              </div>
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="rua"
                  id="rua"
                  placeholder="Rua"
                  onChange={(e) => setQuestionMoradaFaturacaoRua(e.target.value)}
                />
              </div>
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="numPorta"
                  id="numPorta"
                  placeholder="Número porta"
                  onChange={(e) => setQuestionMoradaFaturacaoNumPorta(e.target.value)}
                />
              </div>
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="andarFraccao"
                  id="andarFraccao"
                  placeholder="Andar/Fração"
                  onChange={(e) => setQuestionMoradaFaturacaoAndarFraccao(e.target.value)}
                />
              </div>
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="codPostal"
                  id="codPostal"
                  placeholder="Código Postal"
                  onChange={(e) => setQuestionMoradaFaturacaoCodPostal(e.target.value)}
                />
              </div>
              <div className="custom-rounded-input w-100 mt-2">
                <input
                  type="text"
                  name="localidade"
                  id="localidade"
                  placeholder="Localidade"
                  onChange={(e) => setQuestionMoradaFaturacaoLocalidade(e.target.value)}
                />
              </div>
            </div>
          </div>
        </>
      ),
      answer: null,
      condition: questionCliente === false,
    },
    {
      id: 4,
      quest: "Autorização de acesso aos dados",
      answerElement: (
        <div className="ms-3">
          <ul className="nif-info">
            <li className="info-item">
              <span>
                Instruções para autorizar o acesso aos dados, na E-Redes
              </span>
              <a
                role="button"
                className="input-button"
                href={Pdf}
                rel="noopener noreferrer"
                target="_blank">
                <IconPaperclip size={20} color={"#eb595e"} stroke={1.5} />
              </a>
            </li>
            <li>
              <label role="button" className="info-item">
                {questionAutorizoAcesso ? (
                  <IconCircleCheck size={20} color={"#222"} stroke={1.5} />
                ) : (
                  <IconCircle size={20} color={"#222"} stroke={1.5} />
                )}
                <span>
                  Declaro que autorizei, na E-Redes, o acesso aos meus dados de
                  consumo
                </span>
                <input
                  hidden
                  type={"checkbox"}
                  name="autorizo"
                  onChange={(e) => setQuestionAutorizoAcesso(e.target.checked)}
                />
              </label>
            </li>
          </ul>
        </div>
      ),
      answer: null,
      condition: null,
    },
  ];

  const questElement = (q, k) => (
    <div className="quest-answer mb-4" key={k}>
      <p className="aderir-question">{q.quest}</p>
      {q.answerElement}
    </div>
  );

  const _isMounted = useRef(true);
  useEffect(() => {
    if (_isMounted.current) {
      setQuestionCliente(true);
    }

    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <>
      <div className="hero">
        <div className="row">
          <div className="col-md-4 offset-md-1 hero-img">
            <img src={heroimg} alt="" />
          </div>
          <div className="col-md-6 offset-md-1">
            <span className="hero-quote">Aderir</span>

            <p className="mt-3">
              Caso já seja cliente Usenergy preencha o formulário, caso
              contrário contactar através de email.
            </p>

            <div className="form-adesao mt-4">
              {questions
                .filter((o) => o.id !== 1)
                .map((q, k) =>
                  q.condition != null
                    ? q.condition
                      ? questElement(q, k)
                      : ""
                    : questElement(q, k)
                )}

              <div className="w-100 d-flex justify-content-end">
                <button className="primary-btn" onClick={() => handleAderir()}>
                  Aderir <IconArrowRight size={24} color="#eb595e" stroke={1} />
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer transition={Flip} />
      </div>

      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-overlay__inner">
            <div className="loading-overlay__content">
              <span className="loader"></span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Aderir;
