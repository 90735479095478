import { IconArrowLeft, IconArrowRight, IconCheck } from "@tabler/icons";
import React, { useState } from "react";
import { Flip, toast, ToastContainer } from "react-toastify";
import CustomCheckbox from "../components/CustomCheckbox";
import CustomTextArea from "../components/CustomTextArea";
import CustomTextInput from "../components/CustomTextInput";
import CustomYesNoRadio from "../components/CustomYesNoRadio";
import { toastOptions } from "../utils/Utils";

const questionsArr = [
  {
    id: 1,
    question:
      "A redução dos custos energéticos é um tema preocupante nos dias de hoje, a melhoria da Eficiência Energética é uma das suas preocupações?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 2,
    question:
      "A nível de custos energéticos o preço da energia e o consumo é uma preocupação para si?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 3,
    question:
      "A nível de custos energéticos o preço da Potência é uma preocupação para si?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 4,
    question: "Pretende comparar a sua tarifa com o Mercado regulado?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 5,
    question: "Ao nível de alarmísticas indique quais gostaria de receber:",
    fields: [
      { type: "CHECKBOX", label: "Consumos anómalos pontuais" },
      { type: "CHECKBOX", label: "Otimização da Potência Contratada" },
      { type: "CHECKBOX", label: "Otimização Tarifária" },
      { type: "CHECKBOX", label: "Outro" },
      { type: "TEXT", label: "Outro, indique:" },
    ],
    answer: [],
  },
  {
    id: 6,
    question:
      "Quais as principais medidas de poupança energética que considera:",
    fields: [
      { type: "CHECKBOX", label: "Aplicação de instalação Fotovoltaica" },
      { type: "CHECKBOX", label: "Análise de consumos" },
      {
        type: "CHECKBOX",
        label: "Sensorização e atuação com recurso a IOT (internet Of things)",
      },
    ],
    answer: [],
  },
  {
    id: 7,
    question:
      "Gostaria de Simular o impacto da energia solar na sua casa / empresa?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 8,
    question:
      "Gostaria de ter conseguir comparar os seus consumos com os seus concorrentes ou entre os seus pontos de entrega?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
  {
    id: 9,
    question:
      "Gostaria de conseguir retirar ficheiros dos seus consumos, para melhor analise?",
    fields: [
      { type: "YESNO" },
      {
        type: "TEXTAREA",
        label: "Desejava ter outras informações em formato de listagem:",
      },
    ],
    answer: [],
  },
  {
    id: 10,
    question:
      "Estaria disponível para participar num estudo para acompanhar e melhorar a sua Eficiência Energética (sem qualquer custo associado)?",
    fields: [{ type: "YESNO" }],
    answer: [],
  },
];

const Inquerito = () => {
  const [activeQuestion, setActiveQuestion] = useState(questionsArr[0]);
  const [questions, setQuestions] = useState(questionsArr);
  const activeIsFirst = questions[0].id === activeQuestion.id;
  const activeIsLast = questions[questions.length - 1].id === activeQuestion.id;

  const handleSetAnswer = (value, idx) => {
    const newQuestAnswer = questions.map((q, k) => ({
      ...q,
      answer:
        q.id === activeQuestion.id
          ? {
              ...q.answer,
              [idx]: value,
            }
          : { ...q.answer },
    }));
    setQuestions(newQuestAnswer);
    setActiveQuestion((o) => ({
      ...o,
      answer: { ...o.answer, [idx]: value },
    }));
  };

  const handleQuestionNav = (forward) => {
    setActiveQuestion(
      questions[
        questions.findIndex((o) => o.id === activeQuestion.id) +
          (forward ? 1 : -1)
      ]
    );
  };

  const handleSaveQuestionaire = () => {
    const completedQuest = questions.filter(
      (q) => Object.keys(q.answer).length > 0
    );
    if (completedQuest.length === questions.length) {
      console.log(completedQuest);
      toast.success("Inquérito feito com sucesso", {
        toastOptions,
      });
    } else {
      toast.error("É obrigatório responder a todas as perguntas", {
        toastOptions,
      });
    }
  };

  return (
    <>
      <div className="quest-container">
        <div className="row h-100">
          <div className="col-lg-3 d-none d-lg-block sidebar"></div>
          <div className="col-lg-8 offset-lg-1">
            <div className="quest-body">
              <div className="quest">
                <p className="question">{activeQuestion.question}</p>
                {activeQuestion.fields.map((field, key) => {
                  switch (field.type) {
                    case "YESNO":
                      return (
                        <CustomYesNoRadio
                          key={key}
                          active={activeQuestion.answer?.[key]}
                          setActive={(o) =>
                            handleSetAnswer(o.target.value, key)
                          }
                        />
                      );

                    case "CHECKBOX":
                      return (
                        <CustomCheckbox
                          key={key}
                          active={activeQuestion.answer?.[key]}
                          setActive={(o) =>
                            handleSetAnswer(o.target.checked, key)
                          }
                          label={field.label}
                        />
                      );

                    case "TEXT":
                      return (
                        <CustomTextInput
                          key={key}
                          answer={activeQuestion.answer?.[key]}
                          setAnswer={(o) =>
                            handleSetAnswer(o.target.value, key)
                          }
                          label={field.label}
                        />
                      );

                    case "TEXTAREA":
                      return (
                        <CustomTextArea
                          key={key}
                          answer={activeQuestion.answer?.[key]}
                          setAnswer={(o) =>
                            handleSetAnswer(o.target.value, key)
                          }
                          label={field.label}
                        />
                      );

                    default:
                      return <p key={key}>{field.label}</p>;
                  }
                })}
              </div>
              <div className="quest-nav">
                <div className="quest-nav-buttons">
                  <button
                    className="nav-item"
                    onClick={() => handleQuestionNav(false)}
                    disabled={activeIsFirst}>
                    <IconArrowLeft size={20} color={"#eb595e"} stroke={1.5} />
                    Anterior
                  </button>
                  {!activeIsLast ? (
                    <button
                      className="nav-item text-semibold"
                      onClick={() => handleQuestionNav(true)}>
                      Seguinte
                      <IconArrowRight
                        size={20}
                        color={"#eb595e"}
                        stroke={1.75}
                      />
                    </button>
                  ) : (
                    <button
                      className="nav-item text-semibold"
                      onClick={() => handleSaveQuestionaire()}>
                      Finalizar
                      <IconCheck size={20} color={"#eb595e"} stroke={1.75} />
                    </button>
                  )}
                </div>
                <div className="d-flex flex-column gap-3 align-items-center">
                  <div className="progressbar">
                    <div
                      className="progress"
                      style={{
                        width:
                          (activeQuestion.id * 100) / questions.length + "%",
                        transition: "0.3s",
                      }}></div>
                  </div>
                  <div className="d-flex gap-2 align-items-center">
                    {activeQuestion.id} <span className="text-xs">de</span>{" "}
                    {questions.length}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer transition={Flip} />
    </>
  );
};

export default Inquerito;
