import { IconCircle, IconCircleCheck } from "@tabler/icons";
import React from "react";

const CustomCheckbox = ({ active, setActive, label }) => {
  return (
    <div className="d-flex gap-4 justify-center max-w-sm w-100">
      <label
        className={"w-100 custom-check " + (active ? "check-primary" : "")}
        role="button">
        <div className="d-flex align-items-center">
          {active ? (
            <IconCircleCheck size={28} color={"#eb595e"} stroke={1.75} />
          ) : (
            <IconCircle size={28} color={"#999"} stroke={1.75} />
          )}
        </div>

        <span>{label}</span>
        <input
          hidden
          type="checkbox"
          name="quest1"
          checked={active || false}
          onChange={setActive}
        />
      </label>
    </div>
  );
};

export default CustomCheckbox;
